<template>
  <!--
    Used to display details about the installation.
  -->
  <div class="installationDetail_TrackerV3">
    <LoadingPlaceholder v-if="loading || envLoading || loadingConfig || loadingDiffs" />
    
    <template v-else>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            href="#difference"
            data-toggle="tab"
            aria-controls="difference"
            aria-selected="true"
          >{{ $t('installationDetailTrackerV3View.difference') }}</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="#full"
            data-toggle="tab"
            aria-controls="full"
            aria-selected="false"
          >{{ $t('installationDetailTrackerV3View.full') }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          id="difference"
          class="tab-pane active"
          role="tabpanel"
          aria-labelledby="difference"
        >
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="py-2 pr-4">{{ $t('installationDetailTrackerV3View.filter') }}</label>
            </div>
            <input
              v-model="filter"
              :placeholder="$t('installationDetailTrackerV3View.filterByName')"
              type="text"
              class="form-control rounded-left"
            >
          </div>
          <table class="defaultTable mb-4">
            <thead>
              <tr>
                <th><b>{{ $t('installationDetailTrackerV3View.path') }}</b></th>
                <th><b>{{ $t('installationDetailTrackerV3View.current') }}</b></th>
                <th><b>{{ $t('installationDetailTrackerV3View.default') }}</b></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(difference, key) in computedDifferences"
                :key="`difference-table-row-${key}`"
              >
                <td>{{ difference.path }}</td>
                <td>{{ difference.current }}</td>
                <td>{{ difference.default }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="full"
          class="tab-pane"
          role="tabpanel"
          aria-labelledby="full"
        >
          <div
            v-for="(group, groupKey) in config"
            :key="`group-template-${groupKey}`"
          >
            <h4 class="mb-2">
              {{ groupKey.charAt(0).toUpperCase() + groupKey.slice(1).toLowerCase() }}
            </h4>
            <table class="defaultTable absorb mb-4">
              <thead>
                <tr>
                  <th><b>{{ $t('installationDetailTrackerV3View.name') }}</b></th>
                  <th><b>{{ $t('installationDetailTrackerV3View.value') }}</b></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, pairKey) in group"
                  :key="`pair-table-row-${pairKey}-group-${groupKey}`"
                >
                  <td class="pr-4">
                    {{ pairKey }}
                  </td>
                  <td class="absorbing-column pl-4">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailTrackerV3",
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      environmentInformation: null,
      config: null,
      differences: null,
      installationObject: null,
      envLoading: true,
      filter: "",
      loadingDiffs: true,
      loadingConfig: true
    }
  },
  metaInfo () {
    return {
      title: 'Tracker V3'
    }
  },
  computed: {
    computedDifferences () {
      if (this.filter.length > 0) {
        return this.differences.filter((value) => {
          return value.path.toLowerCase().includes(this.filter.toLowerCase());
        });
      }
      return this.differences;
    }
  },
  created () {
    this.getInstallation();
    this.getEnvironmentInfo();
  },
  methods: {
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.getDifferences();
          this.getConfig();
          this.loading = false;
        });
    },
    getEnvironmentInfo () {
      this.axios.get(`/Installation/GetEnvironmentInformation/${ this.installationId }`)
        .then((result) => {
          if (result == null) {
            return;
          }
          if (result.data == null) {
            return;
          }
          this.environmentInformation = result.data;
        })
        .finally(() => {
          this.envLoading = false;
        });
    },
    getDifferences () {
      let spotId = 0;
      if (this.installationObject.spotId1 != null || this.installationObject.spotId1 != 0) {
        spotId = this.installationObject.spotId1;
      } else if (this.installationObject.spotId2 != null || this.installationObject.spotId2 != 0) {
        spotId = this.installationObject.spotId2;
      } else {
        return;
      }
      this.axios.get(`/Installation/GetConfigDifferences/${ spotId }`)
        .then((result) => {
          if (result == null) {
            return;
          }
          if (result.data == null) {
            return;
          }
          this.differences = result.data;
        })
        .finally(() => {
          this.loadingDiffs = false;
        });
    },
    getConfig () {
      let spotId = 0;
      if (this.installationObject.spotId1 != null || this.installationObject.spotId1 != 0) {
        spotId = this.installationObject.spotId1;
      } else if (this.installationObject.spotId2 != null || this.installationObject.spotId2 != 0) {
        spotId = this.installationObject.spotId2;
      } else {
        return;
      }
      this.axios.get(`/Installation/GetConfig/${ spotId }`)
        .then((result) => {
          if (result == null) {
            return;
          }
          if (result.data == null) {
            return;
          }
          this.config = result.data;
        })
        .finally(() => {
          this.loadingConfig = false;
        });
    }
  }
}
</script>